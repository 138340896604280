import React, {useState} from 'react';
import s from './AllModulsPaid.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import Separator from '../../common/Separator/Separator';
import AnyFormatDate from '../../common/AnyFormatDate/AnyFormatDate'
import {NavLink} from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {TextField} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {productionAPI} from "../../../../api/api";
import {toast} from "react-toastify";
import TableContainer from "@mui/material/TableContainer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Cookies from "js-cookie";
import {Announcement, Info} from "@mui/icons-material";
import IconButton from "@mui/joy/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import AnnouncementIcon from "@mui/icons-material/Announcement";

const AllModulsPaid2 = (props) => {
    //выводим окно с расделением количества модулей
    let [separator, setSeparator] = useState(0)
    let [inOrder, setInOrder] = useState(0)
    let [separatorId, setSeparatorId] = useState(0)
    let [modulsTypeInProduction, setModulsTypeInProduction] = useState(0)


    let redactionColl = (number, id, modulsType) => {
        setSeparator(number)
        setSeparatorId(id)
        setModulsTypeInProduction(modulsType)
    }

    const getModuleType = (module) => {
        if (module.moduls_type_in_production === 3) {
            return module.number + "-Т"
        } else if (module.moduls_type_in_production === 2) {
            return "На склад"
        } else if (module.moduls_type_in_production === 1) {
            return "РФ"
        } else {
            return module.number
        }
    }

    function isModuleReadyForAssemble(module) {
        //console.log(module.is_spareparts_assemble === 1, module.is_spareparts_assemble)
        return module.is_spareparts_assemble === 1
    }

    let other = (id) => {
        props.setOtherProducts(id);
    }
    let setModulsFromStorage = (id) => {
        props.setModulsFromStorage(id);
    }
    let setModus = (id) => {
        props.setOtherProducts(id);
    }
    let collStorageModule = (nameModule, modulsColl = 0, deleteModulesReady = 0) => {
        //console.log(props.modulsReady)
        for (let i = 0; props.modulsReady.length > i; i++) {
            if (nameModule == props.modulsReady[i]["name"]) {
                if (deleteModulesReady === 0) {
                    return " (" + props.modulsReady[i]["coll"] + ")"
                } else {
                    return props.modulsReady[i]["coll"]
                }
            }
        }
    }

    //vars for window
    const [openSpecs, setOpenSpecs] = useState(false);
    const [componentNames, setComponentNames] = useState([]);
    const getSpecs = async (params) => {
        try {
            return await productionAPI.getModulesSpecsForAsembly(params);
        } catch (error) {
            toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
            toast.warning(error.message);
        }
    };
    const [specsTable, setSpecsTable] = useState(null);
    const [currentModule, setCurrentModule] = useState(null);
    const handleClickOpenSpecs = (module) => {
        // console.log(module)
        getSpecs({
            'nomenclature_num': module.all_bom_nomenclatures,
            'all_bom_dateversion': module.all_bom_dateversion,
            'id_invoice': module.id_invoice
        }).then(response => {
            if (response?.status === 1) {
                if (response?.length === 0) {
                    toast.warning('Спецификации не найдены. Обратитесь к инженерам.')
                } else {
                    setCurrentModule(module)
                    setComponentNames(response?.componentNames)

                    toast.success('Спецификации получены')
                    //console.log(response)
                    //specsTable.summarySpecification?.[item]?.itemCode
                    if (Object.keys(response?.summarySpecification).length > 0) {
                        //sort specs table by component code last 3 symbols
                        let specKeys = Object.keys(response.summarySpecification);
                        specKeys.sort((specKeyA, specKeyB) => {
                            const specA = response.summarySpecification[specKeyA];
                            const specB = response.summarySpecification[specKeyB];

                            const specACode = specKeyA.slice(-3);
                            const specBCode = specKeyB.slice(-3);

                            if (specACode < specBCode) {
                                return -1;
                            } else if (specACode > specBCode) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });

                        let updatedSpecKeys = [];
                        specKeys.forEach((key, index) => {
                            updatedSpecKeys.push(response.summarySpecification[key]);
                            if (index < specKeys.length - 1) {
                                const currentCode = key.slice(-3);
                                const nextCode = specKeys[index + 1].slice(-3);
                                if (currentCode !== nextCode) {
                                    updatedSpecKeys.push(null); // Add empty array element
                                }
                            }
                        });

                        //console.log(updatedSpecKeys);
                        setSpecsTable(updatedSpecKeys);
                    } else {
                        setSpecsTable(null);
                        setComponentNames([]);
                    }
                }
            } else {
                toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
                toast.warning(response?.message)
            }
        })
        setOpenSpecs(true);
    };
    const approveComponentMovement = () => {
        return new Promise((resolve, reject) => {
            try {
                // console.log(currentModule);
                //return;
                const params = {
                    'specs': currentModule,
                };

                productionAPI.approveComponentMovement(params)
                    .then(response => {
                        if (response?.status === 1) {
                            toast.success('Перемещение компонентов подтверджено');
                            handleCloseSpecs();
                            //reload page after receiving response from server with response.status===1
                            window.location.reload();
                            //console.log(response);
                            resolve(response);
                        } else {
                            toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
                            toast.warning(response?.message);
                            console.error(response);
                            reject(response);
                        }
                    })
                    .catch(error => {
                        toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
                        toast.warning(error.message);
                        console.error(error);
                        reject(error);
                    });
            } catch (error) {
                toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
                toast.warning(error.message);
                console.error(error);
                reject(error);
            }
        });
    };


    const handleCloseSpecs = () => {
        setSpecsTable(null)
        setOpenSpecs(false);
    };

    let [moduleID, setModuleID] = useState(0)
    let [workerID, setWorkerID] = useState(0)
    let [modulsType, setModulsType] = useState(0)
    let [modulsName, setModulsName] = useState('')
    let [nomenclatureNum, setNomenclatureNum] = useState(0)
    let [modulesToProduce, setModulesToProduce] = useState(0)

//сохраняем данные о том, кто делает модули
    let saveWorkersModuls = (moduleId, workerId, moduls_type_in_production, item_code, misc = {}) => {
        let changeModulsStatus = [moduleId, workerId, moduls_type_in_production, item_code, misc]
        //reload page after receiving response from server with response.status===1

        props.setWhoMakeWithSpecs(changeModulsStatus);
        handleClose();

    }
    //сортируем по дате оплаты, для выставления правильного порядка для работы монтажников
    let sort_payment_date = (arr) => {
        return arr.sort((a, b) => a.main_date > b.main_date ? 1 : -1);
    }
    //сортируем по номенклатурному номеру
    let sort_nomenclature_id = (arr) => {
        return arr.sort((a, b) => a.nomenclatures_id > b.nomenclatures_id ? 1 : -1);
    }
    //сначала показываем на сборку компонентов, потом - на начало производства
    let sort_components_to_dispatch = (arr) => {
        return arr.sort((a, b) => {
            // Check if both items have schet_id specified
            if (a.schet_id && b.schet_id) {
                // If both have schet_id, sort by schet_id
                return a.schet_id > b.schet_id ? -1 : 1; // Sort in descending order of schet_id
            } else if (a.schet_id) {
                // If only a has schet_id, prioritize it
                return -1; // Move a to the beginning
            } else if (b.schet_id) {
                // If only b has schet_id, prioritize it
                return 1; // Move b to the beginning
            } else {
                // If neither has schet_id, sort by other criteria
                return a.main_date > b.main_date ? 1 : -1; // Sort by payment date in ascending order
            }
        });
    }

    const getLinkIfComment = (idCheck, comment) => {
        if (comment && comment.trim().length > 0) return <NavLink
            to={"/check/" + idCheck + "/0"} className={s.comment}
            target="_blank">i</NavLink>
    }

    function isSpecMain(spec) {
        return spec.some(component => component.is_main === 1);
    }

    const [openOldStyleProduction, setOldStyleProductionOpen] = React.useState(false);

    const [oldStyleModulsID, setOldStylsModulsID] = useState(null);
    const [oldStyleModulsName, setOldStyleModulsName] = useState(null);
    const [oldStyleModulsColl, setOldStyleModulsColl] = useState(null);
    const [oldStyleModulsTypeInProduction, setOldStyleModulsTypeInProduction] = useState(null);
    const handleClickOldStyleProductionOpen = () => {
        setOldStyleProductionOpen(true);
    };

    const handleOldStyleProductionClose = () => {
        setOldStylsModulsID(null);
        setOldStyleModulsName(null);
        setOldStyleModulsColl(null);
        setOldStyleModulsTypeInProduction(null);
        setOldStyleProductionOpen(false);
    };


    let module = sort_components_to_dispatch(sort_payment_date(sort_nomenclature_id(props.modulsPaid))).map(moduleInstance =>
        <React.Fragment key={
            isModuleReadyForAssemble(moduleInstance)
                ? moduleInstance.id_invoice + '_' + moduleInstance.schet_id + moduleInstance.all_bom_dateversion
                : moduleInstance.id_invoice + '_' + moduleInstance.id}>
            <div>
                {getModuleType(moduleInstance)}
                {getLinkIfComment(moduleInstance.id_invoice, moduleInstance.comment)}
                {moduleInstance.schet_id && <span style={{color: "red"}}>{moduleInstance.schet_id}</span>}
            </div>
            <div
                className={s.blocks}>{moduleInstance.moduls_name ?? '#' + moduleInstance.all_bom_nomenclatures}
                {(moduleInstance.newDevice === 0) &&
                    <span
                        className={s.red}> {!isModuleReadyForAssemble(moduleInstance) ? '- новое изделие' : 'Сборка запчастей'}</span>}
            </div>
            <div className={s.blocks}>
                {moduleInstance.moduls_coll}
                {!isValidModuleName(moduleInstance.moduls_name) ?
                    <Tooltip title="Возможно несоответствие складских остатков, проверьте вручную">
                        <IconButton>
                            <Announcement/>
                        </IconButton>
                    </Tooltip>
                    : null
                }
                {(!isModuleReadyForAssemble(moduleInstance)) || moduleInstance.moduls_type_in_production === 3 ?
                    <>
                        {collStorageModule(moduleInstance.moduls_name)}

                        {parseInt(moduleInstance.moduls_coll) > 1 &&
                            <span className={s.redactionColl}
                                  onClick={() =>
                                      redactionColl(moduleInstance.moduls_coll, moduleInstance.id, moduleInstance.moduls_type_in_production)
                                  }>
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                            />
                        </span>
                        }
                    </>
                    :
                    <span> {moduleInstance.all_bom_dateversion_count}</span>
                }
            </div>

            {!isModuleReadyForAssemble(moduleInstance)
                ?
                <div className={s.workersMain} style={{textAlign: 'center', justifyContent: 'center'}}>
                    {props.workers.map(worker =>
                            //worker.id != 7 || (worker.id == 7 && parseInt(collStorageModule(moduleInstance.moduls_name, 0, 1)) > 0) ?
                            <div
                                key={worker.id}
                                onClick={() => {
                                    setModuleID(moduleInstance.id);
                                    setWorkerID(worker.id);
                                    setModulsType(moduleInstance.moduls_type_in_production);
                                    setModulsName(moduleInstance.moduls_name);
                                    setNomenclatureNum(moduleInstance.nomenclatures_id);
                                    setModulesToProduce(moduleInstance.moduls_coll);

                                    fetchData({
                                        'order_id': moduleInstance.id,
                                        'worker_id': worker.id,
                                        'moduls_name': moduleInstance.moduls_name,
                                        'moduls_type': moduleInstance.moduls_type_in_production,
                                        'nomenclature_num': moduleInstance.nomenclatures_id
                                    }).then(response => {
                                        if (response?.status === 1) {
                                            if (response?.length === 0) {
                                                toast.warning('Спецификации не найдены. Обратитесь к инженерам.')
                                            } else {
                                                toast.success('Спецификации получены')
                                                // console.log(response)
                                                if (Object.keys(response?.specs).length > 0) {
                                                    let specKeys = Object.keys(response.specs);
                                                    specKeys.sort((specKeyA, specKeyB) => {
                                                        const specA = response.specs[specKeyA];
                                                        const specB = response.specs[specKeyB];

                                                        const specAMain = isSpecMain(specA);
                                                        const specBMain = isSpecMain(specB);

                                                        if (specAMain && !specBMain) {
                                                            return -1;
                                                        } else if (!specAMain && specBMain) {
                                                            return 1;
                                                        } else {
                                                            return 0;
                                                        }
                                                    });
                                                    let sortedSpecs = {};
                                                    specKeys.forEach(key => {
                                                        sortedSpecs[key] = response.specs[key];
                                                    });
                                                    //console.log(sortedSpecs)
                                                    response.specs = [];
                                                    response.specs = sortedSpecs;
                                                    setData(response);
                                                } else {
                                                    setData(null);
                                                }

                                            }
                                        } else {
                                            toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
                                            toast.warning(response?.message)
                                        }
                                    })
                                    handleClickOpen();
                                }
                                }
                                className={s.workerSpan}
                            >
                                {worker.name}
                            </div>
                        //  : null
                    )
                    }
                </div>
                :

                <div style={{textAlign: 'center', justifyContent: 'center'}}>
                    <b>
                        {props.workers.filter(worker => worker.id == moduleInstance.who_make)?.[0]?.name}
                    </b>
                </div>
            }
            <div><AnyFormatDate date={moduleInstance.main_date}/></div>
            <div>
                {isModuleReadyForAssemble(moduleInstance) ?
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleClickOpenSpecs(moduleInstance)}
                    >
                        <Info/> Спецификация
                    </Button>
                    :
                    <>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => other(moduleInstance.id)}>Сторонняя продукция</Button>

                        <Button
                            variant="contained"
                            color="warning"
                            onClick={() => {
                                handleClickOldStyleProductionOpen(moduleInstance);
                                setOldStylsModulsID(moduleInstance.id)
                                setOldStyleModulsName(moduleInstance.moduls_name)
                                setOldStyleModulsColl(moduleInstance.moduls_coll)
                                setOldStyleModulsTypeInProduction(moduleInstance.moduls_type_in_production)
                            }
                                //saveWorkersModulsWithoutSelfcost(module.id, worker.id, module.moduls_type_in_production, module.moduls_name)
                            }>По старинке</Button>

                        <Dialog
                            open={openOldStyleProduction}
                            onClose={handleOldStyleProductionClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            key={moduleInstance.id + '_old_style' + moduleInstance.moduls_type_in_production + moduleInstance.moduls_name + moduleInstance.moduls_coll + moduleInstance.nomenclatures_id + moduleInstance.id_invoice}
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Выбор монтажника для начала производства по старой методике"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Всего модулей <b>{oldStyleModulsName}</b> в
                                    заказе: <b>{oldStyleModulsColl} шт</b>
                                    <br/>
                                    Пожалуйста, выбери монтажника из списка ниже для начала производства по старой
                                    методике.
                                    <br/>
                                    <br/>
                                    Сборка производится без:
                                    <ul>
                                        <li>записи спецификации сборки</li>
                                        <li>учета наличия компонентов на складе</li>
                                        <li>учета стоимости компонентов</li>
                                        <li>учета дефицита компонентов</li>
                                    </ul>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions
                                style={{justifyContent: "space-between"}}
                            >
                                {props.workers.map(worker =>
                                    <Button key={worker.id}
                                            onClick={() =>
                                                saveWorkersModulsWithoutSelfcost(
                                                    worker.id
                                                )}
                                            variant="contained"
                                            color="success"
                                    >
                                        {worker.name}
                                    </Button>)
                                }
                            </DialogActions>
                        </Dialog>

                    </>
                }
                {/* <span onClick={() => setModulsFromStorage(module.id)}>Взять со склада</span> */}

            </div>
        </React.Fragment>)
    ;

    //code for modal window below
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setModuleID(0)
        setWorkerID(0)
        setModulsType(0)
        setModulsName('')
        setDeficit([])
        setBelowMin([])
        setIsProductionConfirmed(false);
        setOpen(false);
    };


    const [data, setData] = useState(null); //specification request

    const fetchData = async (params) => {
        try {
            return await productionAPI.getModulesSpecsWithSelfCost(params);
        } catch (error) {
            toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
            toast.warning(error.message);
        }
    };
    const [isProductionConfirmed, setIsProductionConfirmed] = useState(false);
    const displayAllDeficit = (serverResponse) => {
        setDeficit(serverResponse?.data?.deficit)
        setBelowMin(serverResponse?.data?.below_min)

        setIsProductionConfirmed(true);
        //console.log('deficit:', serverResponse?.data?.deficit)
        //console.log('below_min:', serverResponse?.data?.below_min)
        //console.log(deficit)
    }
    const [usedColl, setUsedColl] = useState(null);
    const checkIfCanBeProduced = async (params) => {
        try {
            const response = await productionAPI.checkIfCanBeProduced(params);
            if (response?.status === 1) {
                toast.success('Проверка наличия запчастей прошла успешно')

                if (params.specs?.length > 1 && Object.keys(response?.data?.deficit).length > 0) {
                    toast.warning('При дефиците компонентов разрешен запуск производства только по одной спецификации');
                    setIsProductionConfirmed(false);
                    return;
                }
                setIsProductionConfirmed(true);

                setUsedColl(response?.data?.usedColl);
                displayAllDeficit(response)
                setSpecQuantities(params);

            } else {
                toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
                toast.warning(response?.message)
                console.error(response)
            }

        } catch (error) {
            toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
            toast.warning(error.message);
            console.error(error)
        }
    };

    //specNinja v2.0
    const [quantityInput, setQuantityInput] = useState({});


    const [belowMin, setBelowMin] = useState([]);
    const [deficit, setDeficit] = useState([]);

    const handleQuantityChange = (event, specName) => {
        setDeficit([]);
        setBelowMin([]);
        setIsProductionConfirmed(false);
        const {value} = event.target;

        if (value === undefined || value === null || value === '') {
            event.target.value = 0;
        }
        if (value < 0) {
            //console.log(value)
            toast.warning('Количество не может быть отрицательным');
            return;
        }

        if (parseInt(value) != value) {
            //console.log(typeof value)
            toast.warning('Количество не может быть дробным');
            return;
        }
        setQuantityInput(prevState => ({
            ...prevState,
            [specName]: value
        }));
        //generateDeficitElementsText();
    };
    const countTotalQuantity = () => {
        return Object.keys(quantityInput).reduce((acc, date) => {
            return acc + parseInt(quantityInput[date]);
        }, 0);
    }
    let specQuantities = {};
    let specQuantitiesSpecs = {};
    const setSpecQuantities = (params) => {
        for (let item in specQuantities) delete specQuantities[item];
        specQuantities = params;
    }

    const formDataObj = () => {
        //for (let item in specQuantities) delete specQuantities[item];
        specQuantities = {};
        specQuantitiesSpecs = {};
        Object.keys(data.specs).forEach(date => {
            if (quantityInput[date] === undefined || quantityInput[date] === null || quantityInput[date] === '' || quantityInput[date] == 0 || quantityInput[date] == "0") {
                delete quantityInput[date];
                return;
            }
            //console.log(quantityInput[date])
            specQuantitiesSpecs[date] = parseInt(quantityInput[date]);
        });

        specQuantities['specs'] = specQuantitiesSpecs;
        specQuantities['moduleID'] = moduleID;
        specQuantities['workerID'] = workerID;
        specQuantities['modulsType'] = modulsType;
        specQuantities['modulsName'] = modulsName;
        specQuantities['nomenclatureNum'] = nomenclatureNum;
        return specQuantities;
    }
    //check if there's enough quantity of each component in storage - request to server
    const handleSubmitProduction = () => {
        if (countTotalQuantity() < modulesToProduce) {
            toast.warning('Количество модулей не должно быть меньше чем в заказе (' + modulesToProduce + ' шт.)');
            return;
        }
        if (countTotalQuantity() > modulesToProduce) {
            toast.warning('Количество модулей не должно быть больше чем в заказе');
            return;
        }
        // Form JS object with spec name as key and input quantity as value
        setIsProductionConfirmed(false);
        specQuantities = formDataObj();

        //console.log(specQuantities);
        checkIfCanBeProduced(specQuantities)
    };
    const handleConfirmProduction = () => {
        specQuantities = formDataObj();
        //if (!startOldProcedure) {
        usedColl && (specQuantities['usedColl'] = usedColl);
        specQuantities['deficit'] = deficit;

        //}
        specQuantities['manager_id'] = Cookies.get('id');

        //console.log(specQuantities)
        saveWorkersModuls(moduleID, workerID, modulsType, nomenclatureNum, specQuantities);
    };
    const saveWorkersModulsWithoutSelfcost = (worker_id) => {
        let request = {
            module_id: oldStyleModulsID,
            worker_id: worker_id,
            moduls_type_in_production: oldStyleModulsTypeInProduction,
            moduls_name: oldStyleModulsName,
            manager_id: Cookies.get('id')
        };
        props.setWhoMakeWithoutSpecs(request);
        handleOldStyleProductionClose();
    }


    const isItemDeficit = (itemCode, specQty = 1) => {
        if (!itemCode || belowMin[itemCode] || deficit[itemCode]) return null;

        const qtyInStorage = data?.stock?.[itemCode]?.['coll'] || 0;
        const qtyMin = data?.stock?.[itemCode]?.['min_coll'] || 0;
        const qtyInSpecs = Object.keys(data.specs).reduce((acc, date) => {
            return acc + (parseFloat(data.specs[date].filter(item => item.component_name === itemCode)?.[0]?.['component_coll']) || 0);
        }, 0);

        const qtyInSpecsMultipliedByModules = qtyInSpecs * parseInt(specQty);
        const newBelowMin = [...belowMin];
        const newDeficit = [...deficit];

        if ((qtyInStorage - qtyInSpecsMultipliedByModules) > qtyMin && qtyInStorage >= qtyInSpecsMultipliedByModules) {
            return ""
        } else if (((qtyInStorage - qtyInSpecsMultipliedByModules) <= qtyMin) && qtyInSpecsMultipliedByModules <= qtyInStorage) {
            //belowMin[itemCode] = qtyMin - (qtyInStorage - qtyInSpecsMultipliedByModules);
            newBelowMin[itemCode] = qtyMin - (qtyInStorage - qtyInSpecsMultipliedByModules);
            setBelowMin(newBelowMin);
        } else {
            //deficit[itemCode] = (qtyInStorage - qtyInSpecsMultipliedByModules);

            newDeficit[itemCode] = (qtyInStorage - qtyInSpecsMultipliedByModules);
            setDeficit(newDeficit);
        }
    }


    // const generateDeficitElementsText = () => {
    //     data?.specs && Object.keys(data.specs).map((date, index) => (
    //             //iterate through current spec and check if there's enough quantity at storage of each component
    //             Object.keys(data.specs[date]).map((spec, index) => {
    //                 //console.log(data.specs[date][spec].component_name)
    //                 console.log(date, index, quantityInput[date])
    //                 //isItemDeficit(data.specs[date][spec].component_name, quantityInput[date])
    //             })
    //         )
    //     )
    //     console.log('deficit', deficit)
    //     console.log('belowMin', belowMin)
    //     //setBelowMin(uniqueBelowMin);
    //
    // }

    const isMainSpec = (date) => {
        return data?.specs[date]?.[0]?.is_main;
    }

    function isValidModuleName(str) {
        // Regular expression pattern allowing only English letters, numbers, and hyphens
        const pattern = /^[a-zA-Z0-9-]+$/;
        return pattern.test(str);
    }

    let currentSectionName = null;

    return (
        <div className={s.main}>
            <>

                <Dialog
                    open={openSpecs}
                    onClose={handleCloseSpecs}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title">
                        Список компонентов, необходимых для выполнения данного заказа
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Код</TableCell>
                                            <TableCell>Название</TableCell>
                                            <TableCell align="right">Кол-во в 1 шт</TableCell>
                                            <TableCell align="right">Кол-во к сборке</TableCell>
                                            <TableCell align="right">Дефицит</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {specsTable && specsTable.map((item, index) => (
                                        <React.Fragment key={`${index}-${specsTable[item]?.itemCode}`}>
                                            {/* Check if the section name has changed */}
                                            {currentSectionName !== componentNames[item?.itemCode]?.section_name && (
                                                <TableRow>
                                                    <TableCell colSpan={5} align="center">
                                                        {/* Render the new section header */}
                                                        {/*componentNames[item?.itemCode]?.section_name*/}
                                                        {/* Update the current section name */}
                                                        {currentSectionName = componentNames[item?.itemCode]?.section_name}
                                                    </TableCell>
                                                </TableRow>
                                            )}


                                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                {item === null
                                                    ?
                                                    <>
                                                        {/*<TableCell component="th" scope="row"></TableCell>
                                                            <TableCell component="th" scope="row"></TableCell>
                                                            <TableCell component="th" scope="row"></TableCell>
                                                            <TableCell component="th" scope="row"></TableCell>
                                                            <TableCell component="th" scope="row"></TableCell>*/}
                                                    </>
                                                    :
                                                    <>
                                                        <TableCell component="th" scope="row">
                                                            {item?.itemCode ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {componentNames[item.itemCode]?.name ?? ''}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {item?.qtyPerPc ?? ''}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(item?.qtyTotally - item?.deficitQty) ?? ''}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {item?.deficitQty ?? ''}
                                                        </TableCell>
                                                    </>
                                                }
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </Table>
                            </TableContainer>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={approveComponentMovement} autoFocus>
                            Перемещено
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>Выбор спецификации для производства</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Пожалуйста, выбери спецификацию из списка ниже для начала сборки модулей.
                            <br/>
                            <br/>
                            Всего модулей <b>{modulsName}</b> в заказе: <b>{modulesToProduce} шт</b>
                            {data ?
                                <div>
                                    {workerID == 7 ?
                                        <>
                                            Текущий остаток на складе: {collStorageModule(modulsName, 0, 1)}
                                        </>
                                        :
                                        <>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Название</TableCell>
                                                            <TableCell align="right">Кол-во</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data?.specs && Object.keys(data.specs).map((date, index) => (
                                                            <TableRow key={`${index}-${date}`}
                                                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                <TableCell component="th" scope="row">
                                                                    {date}
                                                                    {
                                                                        isMainSpec(date)
                                                                            ? <b><i> - основная</i></b>
                                                                            : null
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <TextField
                                                                        type="number"
                                                                        value={quantityInput[date] || 0}
                                                                        onChange={(event) => handleQuantityChange(event, date)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>


                                                </Table>
                                            </TableContainer>
                                            <div>
                                                {Object.keys(deficit).length > 0 ?
                                                    <>
                                                        <div>Дефицит элементов для окончания производства. Процеcс будет
                                                            запущен, однако не сможет быть завершен до получения нужного
                                                            кол-ва
                                                            компонентов:
                                                        </div>
                                                        {
                                                            Object.keys(deficit).length > 1 ??
                                                            <b style={{color: 'red'}}>При дефиците компонентов разрешен
                                                                запуск
                                                                производства только по одной спецификации</b>
                                                        }
                                                        <div>
                                                            {Object.keys(deficit).map((key, index) => (
                                                                <div key={index} style={{color: 'red'}}>
                                                                    {index + 1}. {key} (дефицит: {Math.abs(deficit?.[key]?.after_assembly)} /
                                                                    склад: {deficit[key].available} шт.)
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                    : null
                                                    //console.log('deficit', deficit)
                                                }
                                                {Object.keys(belowMin).length > 0 ?
                                                    <>
                                                        <div>После начала сборки эти элементы будут ниже рекомендуемого
                                                            остатка:
                                                        </div>
                                                        <div>
                                                            {Object.keys(belowMin).map((key, index) => (
                                                                <div key={index} style={{color: 'purple'}}>
                                                                    {index + 1}. {key} (остаток: {belowMin?.[key]?.after_assembly} /
                                                                    минимум: {belowMin[key].min} шт.)
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                    : null

                                                    //console.log('belowMin', belowMin)
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                                :
                                <div style={{color: 'red'}}>Спецификации не найдены</div>
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Отмена</Button>
                        {workerID == 7
                            ?
                            // parseInt(collStorageModule(modulsName, 0, 1)) >= modulesToProduce
                            //    ?
                            <Button onClick={handleConfirmProduction} color="success">Взять со склада</Button>
                            //   :
                            //   <Button color="info" disabled>Недостаточно модулей на складе.</Button>

                            :
                            <>

                                <Button onClick={handleSubmitProduction} color="info">Проверить склады</Button>
                                <Button onClick={handleConfirmProduction} color="success"
                                        style={{display: isProductionConfirmed ? "block" : "none"}}>Запуск</Button>
                            </>
                        }

                    </DialogActions>
                </Dialog>
            </>
            {
                module
            }
            <div></div>
            <div></div>
            <div className={s.blockSaveButton}>
            </div>


            {
                separator !== 0 &&
                <Separator number={separator}
                           setSeparator={setSeparator}
                           setSeparatorInBase={props.setSeparatorInBase}
                           id={separatorId}
                />
            }
        </div>)
}


export default AllModulsPaid2;