import React, { useState, useEffect } from 'react';
import s from './SalesPersonal.module.css';
import WhatType from './withType/WhatType';
import SetPeriod from './common/SetPeriod';
import { NavLink, useParams } from 'react-router-dom';
import Statistics from './common/Statistics';
import Filtr from './Filtr/Filtr';
import Cookies from 'js-cookie';
import AddNewNomenclatures from './AddNewNomenclatures/AddNewNomenclatures';
import Modal from '../../Production/common/Modal/Modal';
import AddNewCompanyContainer from '../Company/AddNewCompany/AddNewCompanyContainer';
import MenuManager from '../common/MenuManager/MenuManager';

const SalesPersonal = (props) => {
    const { personalId: paramPersonalId } = useParams();
    const personalId = Cookies.get('id') === '44' || Cookies.get('id') === '34' ? paramPersonalId : Cookies.get('id');

    let [allActivity, setAllActivity] = useState(props.allActivity);
    useEffect(() => {
        setAllActivity([...props?.allActivity ?? []]);
    }, [props.allActivity]);

    // Sorting activity by date
    const asd = (arr) => arr?.sort((a, b) => (b.dateMain > a.dateMain ? 1 : -1));

    let [showCheck, setShowCheck] = useState(true);
    let [showKp, setShowKp] = useState(true);
    let [showMessage, setShowMessage] = useState(true);
    let [showTech, setShowTech] = useState(true);
    let [showMeeting, setMeeting] = useState(true);
    let [showTestCheck, setShowTestCheck] = useState(true);
    let [showUseCases, setShowUseCases] = useState(true);

    const show = (type) => {
        switch (type) {
            case 1:
                setShowCheck(!showCheck);
                break;
            case 2:
                setShowKp(!showKp);
                break;
            case 3:
                setShowMessage(!showMessage);
                break;
            case 4:
                setShowTech(!showTech);
                break;
            case 5:
                setMeeting(!showMeeting);
                break;
            case 6:
                setShowTestCheck(!showTestCheck);
                break;
            case 666:
                setShowUseCases(!showUseCases);
                break;
            default:
                break;
        }
    };

    // Show only checks for the past 6 months
    const showCheckFor6Months = () => {
        setShowKp(false);
        setShowMessage(false);
        setShowTech(false);
        let now = new Date();
        let day = ("0" + new Date().getDate()).slice(-2);
        let month = now.getUTCMonth() + 1;
        now = now.getFullYear() + "-" + month + "-" + day;

        let end = new Date(now);
        end.setMonth(end.getMonth() - 6);
        let dayEnd = ("0" + end.getDate()).slice(-2);
        let monthEnd = end.getUTCMonth() + 1;
        end = end.getFullYear() + "-" + monthEnd + "-" + dayEnd;

        props.getAllActivity(personalId, end, now);
    };

    let [showFiltr, setShowFiltr] = useState(false);

    const setShowFiltrF = () => {
        setShowKp(true);
        setShowMessage(true);
        setShowTech(true);
        setShowFiltr(false);
        let now = new Date();
        let day = ("0" + new Date().getDate()).slice(-2);
        let month = now.getUTCMonth() + 1;
        now = now.getFullYear() + "-" + month + "-" + day;

        let end = new Date(now);
        end.setDate(end.getDate() - 14);
        let dayEnd = ("0" + end.getDate()).slice(-2);
        let monthEnd = end.getUTCMonth() + 1;
        end = end.getFullYear() + "-" + monthEnd + "-" + dayEnd;

        props.getAllActivity(personalId, end, now);
    };

    let [addCompany, setAddCompany] = useState(false);
    let [lastIdCompany, setLastIdCompany] = useState(props.lastIdCompany);
    useEffect(() => {
        setLastIdCompany(props.lastIdCompany);
    }, [props.lastIdCompany]);

    return (
        <div>
            <Filtr showCheckFor6Months={showCheckFor6Months} setShowFiltr={setShowFiltr} />
            {(Cookies.get('id') === '44' || Cookies.get('id') === '34' || Cookies.get('id') === '1') && (
                <MenuManager idNow={personalId} link="/salesPersonal" />
            )}
            <div className={s.link}>
                <div>
                    {/*
                    <a href="https://master.akon.com.ua/admin/search/company.php" target="_blank" rel="noopener noreferrer">
                            Поиск компаний
                    </a>
                    */}
                </div>

                <div>
                    <span onClick={() => setAddCompany(true)}>Добавить компанию</span>
                </div>
                <div>

                    {/*
                    <a href="https://akon.com.ua/" target="_blank" rel="noopener noreferrer">
                        На сайт
                    </a>
                    */}
                </div>
                <div>
                    <AddNewNomenclatures
                        addNewNomenclatures={props.addNewNomenclatures}
                        statusNomenclatures={props.statusNomenclatures}
                    />
                </div>
            </div>
            <h1>Кабинет взаимодействия</h1>
            {showFiltr ? (
                <div className={s.filter}>
                    <h2>
                        Фильтры:{' '}
                        <span onClick={() => setShowFiltrF(false)}>
                            Только счета за 6 месяцев <span className={s.close}>х</span>
                        </span>
                    </h2>
                </div>
            ) : (
                <div>
                    <SetPeriod getAllActivity={props.getAllActivity} />
                    <Statistics
                        allActivity={allActivity}
                        show={show}
                        showCheck={showCheck}
                        showKp={showKp}
                        showMessage={showMessage}
                        showTech={showTech}
                        showMeeting={showMeeting}
                        showTestCheck={showTestCheck}
                        showUseCases={showUseCases}
                    />
                </div>
            )}

            <div className={s.main + ' ' + s.header}>
                <div>Тип</div>
                <div>Действие</div>
                <div>Дата</div>
            </div>
            {asd(allActivity).map((k, index) => (
                <WhatType
                    type={k.type}
                    {...k}
                    key={`allActivity${index}`}
                    showCheck={showCheck}
                    showKp={showKp}
                    showMessage={showMessage}
                    showTech={showTech}
                    showMeeting={showMeeting}
                    showTestCheck={showTestCheck}
                    showUseCases={showUseCases}
                    personalId = {personalId}
                />
            ))}
            {addCompany && (
                <Modal active={addCompany} setActive={setAddCompany}>
                    <AddNewCompanyContainer active={addCompany} setActive={setAddCompany} />
                </Modal>
            )}
            {lastIdCompany && (
                <Modal active={lastIdCompany} setActive={setLastIdCompany}>
                    <div>
                        <NavLink to={`/company/profile/${lastIdCompany[0] ?? ''}`} target="_blank">
                            <h3>Перейти в карточку клиента</h3>
                        </NavLink>
                        <br />
                        <hr />
                        <div>
                            <b>Название полное:</b> {lastIdCompany[1] ?? ''}
                        </div>
                        <div>
                            <b>Внутреннее название:</b> {lastIdCompany[2] ?? ''}
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default SalesPersonal;
