import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, ButtonGroup, CircularProgress, Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';
import MonthSelector from "../Salary/Assembler/MonthSelector";
import { toast } from 'react-toastify';
import { adminAPI } from "../../../api/api";
import CurrencyFormatter from "../../Sales/common/CurrencyFormatter/CurrencyFormatter";

const VATPage = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0'); // формат MM

    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentMonth);
    const [monthName, setMonthName] = useState(''); // Російська назва місяця
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    // State для діалогового вікна
    const [openDialog, setOpenDialog] = useState(false);
    const [additionalVATAmount, setAdditionalVATAmount] = useState('');
    const [additionalVATDate, setAdditionalVATDate] = useState(new Date().toISOString().split('T')[0]); // Today’s date

    const closeDialog = () => {
        setAdditionalVATAmount('');
        setAdditionalVATDate(new Date().toISOString().split('T')[0]);
        setOpenDialog(false);
    }
    // Функція для завантаження даних
    const fetchVATData = async (selectedYear, selectedMonth) => {
        setLoading(true);
        try {
            await adminAPI.receiveVATInfo({
                params: { year: selectedYear, month: selectedMonth }
            }).then(response => {
                if (response?.status === 1) {
                    setData(response?.data); // Зберігаємо дані в стан
                    toast.success('Дані отримано');
                } else {
                    toast.error('Ошибка получения данных: ' + response?.data.message || 'неизвестная ошибка');
                }
            }).catch(error => {
                toast.error('Помилка отримання даних: ' + error);
            });
        } catch (error) {
            console.error("Ошибка получения данных:", error);
        } finally {
            setLoading(false);
        }
    };

    // Завантаження даних для поточного місяця при першому рендерингу
    useEffect(() => {
        fetchVATData(year, month);
    }, [year, month]);

    // Генерація кнопок для вибору року
    const renderYearButtons = () => {
        const startYear = 2021;
        const years = [];
        for (let y = startYear; y <= currentYear; y++) {
            years.push(
                <Button
                    key={y}
                    variant={y === year ? 'contained' : 'outlined'}
                    onClick={() => setYear(y)}
                >
                    {y}
                </Button>
            );
        }
        return years;
    };

    // Обробка збереження додаткового НДС
    const handleSaveAdditionalVAT = async () => {
        try {
            await adminAPI.saveAdditionalVAT({
                money: parseFloat(additionalVATAmount),
                created_at: additionalVATDate,
            });
            toast.success('Дополнительный платёж добавлен');
            closeDialog();
            fetchVATData(year, month); // Оновити дані після додавання
        } catch (error) {
            toast.error('Ошибка при сохранении платежа.');
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                НДС за периоды
            </Typography>

            {/* Вибір року */}
            <ButtonGroup variant="outlined" color="primary" aria-label="year selector" style={{ marginBottom: '20px' }}>
                {renderYearButtons()}
            </ButtonGroup>

            <br />

            {/* Вибір місяця */}
            <MonthSelector selectedMonth={month} onSelect={setMonth} onSelectName={setMonthName} />

            <br />
            {/* Кнопка для додавання додаткового НДС */}
            <Button variant="contained" color="primary" onClick={() => {
                setAdditionalVATDate(new Date().toISOString().split('T')[0]);
                setOpenDialog(true)
            }} style={{ marginTop: '20px' }}>
                Добавить дополнительный платёж (сумма с НДС!)
            </Button>

            {/* Діалогове вікно для введення додаткового НДС */}
            <Dialog open={openDialog} onClose={() => closeDialog()}>
                <DialogTitle>Добавить платёж НДС</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Сума рахунку з НДС"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={additionalVATAmount}
                        onChange={(e) => setAdditionalVATAmount(e.target.value)}
                        sx={{ marginTop: '20px' }}
                        InputProps={{ inputProps: { step: 0.01 } }}
                    />
                    <TextField
                        label="Дата"
                        type="date"
                        fullWidth
                        variant="outlined"
                        value={additionalVATDate}
                        onChange={(e) => setAdditionalVATDate(e.target.value)}
                        style={{ marginTop: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog()} color="secondary">Відмінити</Button>
                    <Button onClick={handleSaveAdditionalVAT} color="primary">Зберегти</Button>
                </DialogActions>
            </Dialog>

            {/* Плейсхолдер під час завантаження */}
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: '20px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                data && (
                    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Период</TableCell>
                                    <TableCell align="right">Сумма НДС мы должны</TableCell>
                                    <TableCell align="right">Сумма НДС нам должны</TableCell>
                                    <TableCell align="right">Сумма НДС нам должны доп.</TableCell>
                                    <TableCell align="right">Сумма НДС нам должны всего</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>1-15 {monthName}</TableCell>
                                    <TableCell align="right"><CurrencyFormatter amount={data.we.firstHalf} /></TableCell>
                                    <TableCell align="right"><CurrencyFormatter amount={data.government.firstHalf} /></TableCell>
                                    <TableCell align="right"><CurrencyFormatter amount={data.additionalVAT.firstHalf} /></TableCell>
                                    <TableCell align="right"><CurrencyFormatter amount={(parseFloat(data.additionalVAT.firstHalf) + parseFloat(data.government.firstHalf))} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>16-31 {monthName}</TableCell>
                                    <TableCell align="right"><CurrencyFormatter amount={data.we.secondHalf} /></TableCell>
                                    <TableCell align="right"><CurrencyFormatter amount={data.government.secondHalf} /></TableCell>
                                    <TableCell align="right"><CurrencyFormatter amount={data.additionalVAT.secondHalf} /></TableCell>
                                    <TableCell align="right"><CurrencyFormatter amount={(parseFloat(data.additionalVAT.secondHalf) + parseFloat(data.government.secondHalf))} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Всього за {monthName}</TableCell>
                                    <TableCell align="right"><b><CurrencyFormatter amount={(parseFloat(data.we.firstHalf) + parseFloat(data.we.secondHalf))} /></b></TableCell>
                                    <TableCell align="right"><b><CurrencyFormatter amount={(parseFloat(data.government.firstHalf) + parseFloat(data.government.secondHalf))} /></b></TableCell>
                                    <TableCell align="right"><b><CurrencyFormatter amount={(parseFloat(data.additionalVAT.firstHalf) + parseFloat(data.additionalVAT.secondHalf))} /></b></TableCell>
                                    <TableCell align="right"><b><CurrencyFormatter amount={(parseFloat(data.additionalVAT.firstHalf) + parseFloat(data.additionalVAT.secondHalf) + parseFloat(data.government.firstHalf) + parseFloat(data.government.secondHalf))} /></b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            )}
        </div>
    );
};

export default VATPage;
