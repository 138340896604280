import React from 'react';

const CurrencyFormatter = ({ amount, withSpaces = true, onlyHRN = false }) => {
    if (!amount) return '';

    const formatNumberWithSpaces = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const options = {
        style: 'currency',
        currency: 'UAH',
        minimumFractionDigits: onlyHRN ? 0 : 2,
        maximumFractionDigits: onlyHRN ? 0 : 2,
    };

    const formattedAmount = amount.toLocaleString('uk-UA', options);

    if (withSpaces) {
        return formatNumberWithSpaces(formattedAmount);
    }

    return formattedAmount;
};

export default CurrencyFormatter;
