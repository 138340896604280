import React from 'react';
import flag from "./ukraine_flag.png";
import s from './Status.module.css'
import Cookies from "js-cookie";

const Status = (props) => {
    //console.log(props.moduleInfo)
    function formatDate(inputDate = '') {
        if (inputDate === 0 || inputDate === null || inputDate === '') return '';

        const dateParts = inputDate.trim().split(/[-: ]/);

        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];
        const hours = dateParts[3] || '';
        const minutes = dateParts[4] || '';

        let response = day + "." + month;
        if (hours !== '') {
            response += " " + hours + ":" + minutes;
        }
        //console.log( dateParts,response)
        return response;
    }

    function getWorkingDaysDifference(startDate = '', endDate = '') {
        if (startDate === '' || endDate === '' || startDate === null || endDate === null) return null;
        let start = new Date(startDate);
        let end = new Date(endDate);
        if (start.getTime() === end.getTime()) return null;
        if (start > end) [start, end] = [end, start];
        let workingDays = 0;
        let currentDate = new Date(start);
        while (currentDate <= end) {
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
                workingDays++;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return workingDays;
    }

    const renderFlag = () => {
        let isAdmin = [1, 34, 44].includes(parseInt(Cookies.get('id')))
        if (([2, 3, 4].includes(props.status) && ([297, 993].includes(parseInt(Cookies.get('id'))))) || isAdmin) {
            return <img src={flag} onClick={() => props.setModulsStatusNew({
                'status': props.status,
                'moduleId': props.modulsId,
                'managerID': Cookies.get('id'),
                'modules_in_invoice_coll': props.moduleInfo?.moduls_coll,
                'moduleInfo': props.moduleInfo
            })} className={s.active}/>;
        }
        return <img src={flag} />;
    };


    const renderDateWithDuration = (date, duration) => {
        return (
            <>
                {date}
                {duration !== null && duration !== 0 && <><br/>(Раб.дней: {duration})</>}
            </>
        );
    };

    //const { start_date_installation, date_primary_control, date_corpus, date_output_control, date_storage } = props.moduleInfo;
    let start_date_installation = formatDate(props?.moduleInfo?.date_start_installation ?? '')
    let date_primary_control = formatDate(props?.moduleInfo?.date_primary_control ?? '')
    let date_corpus = formatDate(props?.moduleInfo?.date_corpus ?? '')
    let date_output_control = formatDate(props?.moduleInfo?.date_output_control ?? '')
    let date_storage = formatDate(props?.moduleInfo?.date_storage ?? '')


    switch (props.status) {
        case 0:
            return (
                <>
                    <div>{renderFlag()}</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </>
            );
        case 1:
            return (
                <>
                    <div>{start_date_installation}</div>
                    <div>{renderFlag()}</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </>
            );
        case 2:
            return (
                <>
                    <div>{start_date_installation}</div>
                    <div>{date_primary_control}</div>
                    <div>{renderFlag()}</div>
                    <div></div>
                    <div></div>
                    <div></div>
                </>
            );
        case 3:
            return (
                <>
                    <div>{(start_date_installation)}</div>
                    <div>{date_primary_control}</div>
                    <div>{date_corpus}</div>
                    <div>{renderFlag()}</div>
                    <div></div>
                    <div></div>
                </>
            );
        case 4:
            return (
                <>
                    <div>{(start_date_installation)}</div>
                    <div>{date_primary_control}</div>
                    <div>{date_corpus}</div>
                    <div>{date_output_control}</div>
                    <div>{renderFlag()}</div>
                    <div></div>
                </>
            );
        case 5:
            return (
                <>
                    <div>{(start_date_installation)}</div>
                    <div>{date_primary_control}</div>
                    <div>{date_corpus}</div>
                    <div>{date_output_control}</div>
                    <div>{date_storage}</div>
                    <div>{renderFlag()}</div>
                </>
            );
        default:
            return "Всё сломалось";
    }
};

export default Status;
