import React, {useState} from 'react';
import s from './ManagerSalary.module.css';
import main from '../../Main.module.css';
import Cookies from 'js-cookie';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import MenuManager from "../common/MenuManager/MenuManager";
import MonthBlock from "../../Production/common/MonthBlock/MonthBlock";
import {NavLink, useParams} from "react-router-dom";
import CurrencyFormatter from "../common/CurrencyFormatter/CurrencyFormatter";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {salesAPI} from "../../../api/api";
import {toast} from "react-toastify";
const ManagerSalary = (props) => {
    // Отримуємо параметри з useParams один раз
    const {idManager: paramIdManager, month: paramMonth, year: paramYear} = useParams();
    let personalId = Cookies.get('id');

    // Перевірка на випадок адміна
    let idManager = paramIdManager ?? personalId;
    if (Cookies.get('id') === "44" || Cookies.get('id') === "34") {
        idManager = paramIdManager;  // Використовуємо id з params, якщо це адмін
    }

    const today = new Date();
    const month = paramMonth ?? String(today.getMonth() + 1).padStart(2, '0'); // Значення за замовчуванням
    const year = paramYear ?? String(today.getFullYear());

    const normalizeManagerID = (id) => {
        if (id === undefined || id === null) return null;
        id = parseInt(id);
        if (id === 1) return 1;
        if (id === 44) return 1;
        if (id === 2) return 486;
        return id;
    };

    const denormalizeManagerID = (id) => {
        if (id === undefined || id === null) return null;
        id = parseInt(id);
        if (id === 1) return 44;
        if (id === 2) return 486;
        return id;
    };

    let managerSalary = [];
    let manager2Salary = [];
    let invoiceSum = 0.0;

    function filterManagers(managerData, id) {
        return Object.keys(managerData || {}).filter(key => denormalizeManagerID(key) == id);
    }

    const managerId = Cookies.get('id');
    const filteredManagers = filterManagers(props?.relatedPaymentForYearMonth?.manager_names, managerId);


    const [openDialog, setOpenDialog] = useState(false);
    const [percent1, setPercent1] = useState(0);
    const [percent2, setPercent2] = useState(0);
    const [checkid, setCheckId] = useState(null);
    const [hasManager2, setHasManager2] = useState(false);

    const openDialogChangePercent = (check_id, whose_company2, manager1_percent, manager2_percent) => {
        console.log('openDialogChangePercent', check_id, manager1_percent, manager2_percent);
        setPercent1(manager1_percent ?? 5);
        setCheckId(check_id)
        if(whose_company2 === null) {
            setHasManager2(false);
        }else{
            setHasManager2(true);
            setPercent2(manager2_percent ?? 2)
        }
        setOpenDialog(true);
    }

    const saveCheckPercentage = async () => {
        console.log('saveCheckPercentage', checkid, percent1, percent2);

        // Save the percentage
        await salesAPI.changeDealManagerPercentage({
            check_id: checkid,
            manager1_percent: percent1,
            manager2_percent: percent2
        }).then((response) => {
          if(response.status===1) {

              closeDialogChangePercent();
              window.location.reload()
          }else{
              toast.error('Ошибка при сохранении процентов: ' + response.message);
          }
        }).catch((error) => {
            toast.error('Ошибка при сохранении процентов: ' + error.message);
        })
    }

    const closeDialogChangePercent = () => {
        console.log('closeDialogChangePercent');
        setPercent1(0)
        setPercent2(0)
        setHasManager2(false)
        setOpenDialog(false)
        setCheckId(null)
    }

    return (
        <div>

            <Dialog open={openDialog} onClose={closeDialogChangePercent}>
                <DialogTitle>Изменить процент</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            <div>Процент менеджера 1</div>
                            <input type="text" value={percent1} onChange={(e) => setPercent1(e.target.value)}/>
                        </div>
                        <div style={{ display:hasManager2 ? 'block' :"none"}}>
                            <div>Процент менеджера 2</div>
                            <input type="text" value={percent2} onChange={(e) => setPercent2(e.target.value)}/>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveCheckPercentage} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>



            <div className={s.mainYear}>
                <div className={year === "2021" ? s.active : ""}>
                    <NavLink to={"/sales/salary/" + personalId + '/2021/' + month}>2021</NavLink>
                </div>
                <div className={year === "2022" ? s.active : ""}>
                    <NavLink to={"/sales/salary/" + personalId + '/2022/' + month}>2022</NavLink>
                </div>
                <div className={year === "2023" ? s.active : ""}>
                    <NavLink to={"/sales/salary/" + personalId + '/2023/' + month}>2023</NavLink>
                </div>
                <div className={year === "2024" ? s.active : ""}>
                    <NavLink to={"/sales/salary/" + personalId + '/2024/' + month}>2024</NavLink>
                </div>
            </div>

            <div>
                <MonthBlock path={"sales/salary/" + idManager + '/' + year} month={month}/>
            </div>

            <div className={s.mainYear}></div>
            <div className={s.mainYear}>
                {(Cookies.get('id') === "44" || Cookies.get('id') === "34") && (
                    <MenuManager link={"/sales/salary"}
                                 managerSalary={true}
                                 year={year}
                                 month={month}
                                 idNow={idManager}
                    />
                )}
            </div>

            <h1>% менеджера за выбраный период</h1>

            <div className={s.main + " " + main.bold}>
                <div className={main.center}>#</div>
                <div className={main.center}>№ счёта</div>
                <div className={main.center}>Сумма счёта</div>
                <div className={main.center}>Дата оплаты счёта</div>
                <div className={main.center}>Чей счёт</div>
                <div className={main.center}><a title={"Второй менеджер"}>Чайка</a></div>
            </div>
            {

                props.relatedPaymentForYearMonth
                && props.relatedPaymentForYearMonth.operations !== undefined
                && props.relatedPaymentForYearMonth.operations.length > 0
                    ?

                    (
                        props.relatedPaymentForYearMonth.operations.map((k, index) => {
                            k.whose_company = normalizeManagerID(k.whose_company);
                            k.whose_company2 = normalizeManagerID(k.whose_company2);

                            invoiceSum += parseFloat(k.money);


                            k.manager1_percent === null
                                ?
                                k.whose_company2 === null
                                    ? managerSalary[k.whose_company] = (managerSalary[k.whose_company] ?? 0) + parseFloat(k.money / 1.2 * 5 / 100)
                                    : managerSalary[k.whose_company] = (managerSalary[k.whose_company] ?? 0) + parseFloat(k.money / 1.2 * 3 / 100)

                                : managerSalary[k.whose_company] = (managerSalary[k.whose_company] ?? 0) + parseFloat(k.money / 1.2 * k.manager1_percent / 100)
                            k.manager2_percent === null
                                ? manager2Salary[k.whose_company2] = (manager2Salary[k.whose_company2] ?? 0) + parseFloat(k.money / 1.2 * 2 / 100)
                                : manager2Salary[k.whose_company2] = (manager2Salary[k.whose_company2] ?? 0) + parseFloat(k.money / 1.2 * k.manager2_percent / 100)


                            k.whose_company2 == 1 ? k.whose_company2 = 44 : k.whose_company2 = k.whose_company2;
                            return (
                                <div key={"relatedPaymentForYearMonth_" + index} className={s.main}>
                                    <div className={main.center}>{index + 1}</div>
                                    <div className={main.center}><NavLink to={"/check/" + k.check_id + "/0"}
                                                                          target="_blank"
                                                                          className={s.link}>{k.number}</NavLink></div>
                                    <div className={main.center}><CurrencyFormatter amount={parseFloat(k.money ?? 0)}/>
                                    </div>
                                    <div className={main.center}><AnyFormatDate date={k.date}/></div>
                                    <div className={main.center}>
                                        {props.relatedPaymentForYearMonth.manager_names[denormalizeManagerID(k.whose_company)] ?? k.whose_company}
                                        <br/>
                                        {k.manager1_percent === null
                                            ?

                                            (k.whose_company2 == null
                                                    ?
                                                    (<>
                                                    <span
                                                        className={main.green}> <CurrencyFormatter
                                                        amount={parseFloat(k.money / 1.2 * 5 / 100)}/> (5%)</span>
                                                        </>
                                                    )
                                                    :
                                                    (<>
                                                    <span
                                                        className={main.green}> <CurrencyFormatter
                                                        amount={parseFloat(k.money / 1.2 * 3 / 100)}/> (3%)</span>
                                                    </>)
                                            )
                                            :
                                            (<>
                                            <span
                                                className={main.green}> <CurrencyFormatter
                                                amount={parseFloat(k.money / 1.2 * k.manager1_percent / 100)}/> ({k.manager1_percent}%)</span>
                                            </>)
                                        }
                                        {(Cookies.get('id') === "44" || Cookies.get('id') === "34" || Cookies.get('id') === "1") &&
                                        <span onClick={() => {
                                            openDialogChangePercent(k.check_id, k.whose_company2, k.manager1_percent, k.manager2_percent)
                                        }} className={s.pencil}>
                                            <FontAwesomeIcon icon={faPencilAlt}/>
                                        </span>
                                        }
                                    </div>
                                    <div className={main.center}>
                                        {k.whose_company2 == null ? '' : (
                                            (k.manager2_percent === null
                                                    ? (
                                                        <>
                                                            {props.relatedPaymentForYearMonth.manager_names[denormalizeManagerID(k.whose_company2)] ?? k.whose_company2}
                                                            <br/>
                                                            <span
                                                                className={main.blue}><CurrencyFormatter
                                                                amount={parseFloat(k.money / 1.2 * 2 / 100)}/> (2%)</span>
                                                        </>)
                                                    :
                                                    (<>
                                                        {props.relatedPaymentForYearMonth.manager_names[denormalizeManagerID(k.whose_company2)]
                                                            ?? k.whose_company2} <br/>
                                                        <span
                                                            className={main.blue}> <CurrencyFormatter
                                                            amount={parseFloat(k.money / 1.2 * k.manager2_percent / 100)}/> ({k.manager2_percent}%)</span>
                                                    </>)
                                            )
                                        )}
                                    </div>
                                </div>
                            )
                        })

                    )
                    :
                    <>
                        <div className={s.main + main.colspanGrid6}>
                            <div>Нет информации об оплаченых счетах за выбранный период</div>
                        </div>
                    </>

            }
            <div className={s.main}>
                <div></div>
                <div></div>
                <div className={main.center}><CurrencyFormatter amount={invoiceSum}/></div>
                <div></div>
                <div className={main.center}>
                    {managerSalary.map((k, index) => (
                        <span
                            key={index}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center', // Vertical alignment
                            }}
                        >
                                <span style={{flex: 1, textAlign: 'left'}}>
                                    {props.relatedPaymentForYearMonth.manager_names[denormalizeManagerID(index)]}
                                </span>
                                <span style={{flex: 1, textAlign: 'right'}}>
                                    <CurrencyFormatter amount={parseFloat(k)}/>
                                </span>
                            </span>
                    ))
                    }
                </div>
                <div className={main.center}>
                    {Cookies.get('id') === "44" || Cookies.get('id') === "34" || Cookies.get('id') === "1" ? (
                        manager2Salary.map((k, index) => (
                            <span key={index} style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '5px 0'
                            }}>
                                <span style={{flex: 1, textAlign: 'left'}}>
                                    {props.relatedPaymentForYearMonth.manager_names[denormalizeManagerID(index)]}
                                </span>
                                <span style={{flex: 1, textAlign: 'right'}}>
                                    <CurrencyFormatter amount={parseFloat(k).toFixed(2)}/>
                                </span>
                            </span>
                        ))
                    ) : (
                        <span>
                            <CurrencyFormatter amount={parseFloat(manager2Salary[Cookies.get('id')])}/>
                        </span>
                    )}
                </div>
            </div>


            <h3 className={main.center}>Сумма по данной выборке</h3>

            <div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div>
                    {Object.entries(props?.relatedPaymentForYearMonth?.manager_names).map(([id, name]) => {
                        // console.log(id, normalizeManagerID(id), name);
                        // console.log(managerSalary[normalizeManagerID(id)] || 0, manager2Salary[normalizeManagerID(id)] || 0);
                        const totalSalary = (managerSalary[normalizeManagerID(id)] || 0) + (manager2Salary[normalizeManagerID(id)] || 0);

                        return (
                            <div key={'total_salary_' + id} style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '5px 0'
                            }}>
            <span style={{flex: 1, textAlign: 'left'}}>
                {name}
            </span>
                                <span>
                <CurrencyFormatter amount={parseFloat(totalSalary)}/> <br/>
            </span>
                            </div>
                        );
                    })}

                </div>
                <div></div>
            </div>

        </div>
    )
        ;
};

export default ManagerSalary;
